import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileSaver from 'file-saver';
import XLSX from 'xlsx';
export default {
  props: {
    id: {
      type: String,
      //table ID名称
      default: 'table'
    },
    name: {
      type: String,
      //导出需要的名字
      default: 'Table'
    }
  },
  data: function data() {
    return {
      downloadLoading: false
    };
  },
  components: {},
  methods: {
    // 导出Excel表格
    exportExcel: function exportExcel() {
      this.downloadLoading = true;
      //  .table要导出的是哪一个表格
      var table = document.querySelector('#' + this.id).cloneNode(true);
      // 因为element-ui的表格的fixed属性导致多出一个table，会下载重复内容，这里删除掉
      table.removeChild(table.querySelector(".el-table__fixed"));
      var wb = XLSX.utils.table_to_book(table, {
        raw: true
      }); //mytable为表格的id名
      // var xlsxParam = { raw: true };
      // var wb = XLSX.utils.table_to_book(document.querySelector('#' + this.id), xlsxParam);
      var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      });
      try {
        FileSaver.saveAs(new Blob([wbout], {
          type: 'application/octet-stream'
        }), this.name + '.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout);
      }
      this.downloadLoading = false;
      return wbout;
    }
  }
};